import { useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.css";
import { Footer, Navbar } from "./components";
import { AppRoutes } from "./models";
import { Homepage } from "./pages/homepage/homepage.page";
import { JobDescription } from "./pages/jobDescription/jobDescription.page";
import { JobSearchResult } from "./pages/jobSearchResult/jobSearchResult.page";
import { IpAddressResolverService } from "./services/ipAddressResolverService";
import { LocationResolverService } from "./services/locationResolverService";
import {
  setAvailableEmploymentTypes,
  setAvailableJobCategories,
  setClientAvailableLocations,
  setClientCountry,
  setClientIp,
} from "./store";
import store from "./store/store";
import { Country, defaultCountrySelection, Location, User } from "./types";
import { AppLoader } from "./components/appLoader/appLoader";
import { JobService } from "./services/jobsService";
import { RedirectExternalPage } from "./pages/redirectExternalPage/redirectExternal.page";
import { SignUpPage } from "./pages/signup/signup.page";
import { LoginPage } from "./pages/login/login.page";
import { setUser } from "./store/authSlice";
import { DashboardPage } from "./pages/dashboard/dashboard.page";

const router = createBrowserRouter([
  {
    path: AppRoutes.HOME,
    element: (
      <>
        <Navbar />
        <Homepage />
        <Footer />
      </>
    ),
  },
  {
    path: AppRoutes.JOB_SEARCH,
    element: (
      <>
        <Navbar />
        <JobSearchResult />
        <Footer />
      </>
    ),
  },
  {
    path: `${AppRoutes.JOB}/:cc/:title/:sr`,
    element: (
      <>
        <Navbar />
        <JobDescription />
        <Footer />
      </>
    ),
  },
  {
    path: `${AppRoutes.COMPLETE_APPLICATION}`,
    element: (
      <>
        <Navbar />
        <RedirectExternalPage />
        <Footer />
      </>
    ),
  },
  {
    path: `${AppRoutes.LOGIN}`,
    element: (
      <>
        <Navbar />
        <LoginPage />
        <Footer />
      </>
    ),
  },
  {
    path: `${AppRoutes.SIGNUP}`,
    element: (
      <>
        <Navbar />
        <SignUpPage />
        <Footer />
      </>
    ),
  },
  {
    path: `${AppRoutes.DASHBOARD}`,
    element: (
      <>
        <Navbar />
        <DashboardPage />
        <Footer />
      </>
    ),
  },
]);

function App() {
  const ipAddressResolverService: IpAddressResolverService =
    new IpAddressResolverService();
  const locationResolverService: LocationResolverService =
    new LocationResolverService();
  const jobService: JobService = new JobService();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    console.log("Init App");

    /// TODO: create app initialization service to handle this
    initializeApp();
  }, []);

  async function initializeApp() {
    setIsLoading(true);
    /// resolve client ip
    const clientIp: string =
      await ipAddressResolverService.resolveClientIpAddress();

    /// Sample UK IP 102.165.0.255
    /// resolve client location country
    let country: Country | null =
      await locationResolverService.resolveIpToCountry("102.165.0.255");
    if (!country) {
      console.log("fallback to default country US");
      country = defaultCountrySelection;
    }

    let clientAvailableLocations: Location[] = [];
    let availableJobCategories: string[] = [];
    let availableEmploymentTypes: string[] = [];

    /// resolve client available locations
    clientAvailableLocations =
      await locationResolverService.getClientAvailableLocations(
        country.countryName
      );

    /// resolve client available job categories
    availableJobCategories = await jobService.findJobCategoryByCountry(
      country.countryName
    );

    /// resolve client available employment types
    availableEmploymentTypes = await jobService.findJobEmploymentTypesByCountry(
      country.countryName
    );

    /// check if user is logged in, then sync the redux state
    const user: User | null = sessionStorage.getItem("user")
      ? JSON.parse(sessionStorage.getItem("user") as string)
      : null;
    store.dispatch(setUser(user));

    store.dispatch(setClientIp(clientIp));
    store.dispatch(setClientCountry(country));
    store.dispatch(setClientAvailableLocations(clientAvailableLocations));
    store.dispatch(setAvailableJobCategories(availableJobCategories));
    store.dispatch(setAvailableEmploymentTypes(availableEmploymentTypes));
    setIsLoading(false);
  }

  return (
    <>
      {isLoading && <AppLoader />}
      {!isLoading && <RouterProvider router={router} />}
    </>
  );
}

export default App;
