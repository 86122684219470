import { configureStore } from '@reduxjs/toolkit';
import searchResultPageReducer from './searchResultPageSlice';
import userMetaDataReducer from './userMetaDataSlice';
import redirectPageReducer from './redirectPageSlice';
import searchFilterReducer from './searchFilterSlice';
import authReducer from './authSlice';

const store = configureStore({
	reducer: {
		searchFilter: searchFilterReducer,
		searchResultPage: searchResultPageReducer,
		userMetaData: userMetaDataReducer,
		redirectPage: redirectPageReducer,
		auth: authReducer
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false
		}),
});


export default store;
export type RootState = ReturnType<typeof store.getState>;