import { HttpStatusCode } from "axios";
import { LocationApiConnector } from "../serverConnectors";
import { City, Country, Location } from "../types";

export class LocationResolverService extends LocationApiConnector {

    async resolveIpToCountry(ipAddress: string): Promise<Country | null> {
        let country: Country | null = null;
        try {
            const response = await this.axios.get(`/ip?ip=${ipAddress}`);
            if (response.status === HttpStatusCode.Ok) {
                country = response.data;
            }
        }
        catch (error: any) {
            console.log("Error: ", error);
        }
        return country;
    }

    async getClientAvailableLocations(countryName: string): Promise<Location[]> {
        let locationSuggestions: Location[] = JSON.parse(localStorage.getItem('availableLocations') || '[]');
        if (locationSuggestions.length > 0) {
            return locationSuggestions;
        }
        try {
            const response = await this.axios.post("/city", {
                countryName: countryName,
                cityName: ""
            });
            if (response.status === HttpStatusCode.Ok) {
                console.log(response.data);
                locationSuggestions = response.data;
            }
            if (localStorage.getItem('availableLocations') === null) {
                localStorage.setItem('availableLocations', JSON.stringify(locationSuggestions));
            }
        }
        catch (error: any) {
            console.log("Error: ", error);
        }
        return locationSuggestions;
    }

    async searchCityByName(countryName: string, regionName: string, cityName: string): Promise<City | null> {
        let city: City | null = null;
        try {
            const response = await this.axios.get(`/city?city=${cityName}&region=${regionName}&country=${countryName}`);
            if (response.status === HttpStatusCode.Ok) {
                console.log("SearchCityByName: ", response.data);
                city = response.data;
            }
        }
        catch (error: any) {
            console.log("Error: ", error);
        }
        return city;
    }
}