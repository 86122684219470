import axios, { AxiosInstance } from "axios";
import { environment } from "../environment";

export class AuthConnector {
    axios: AxiosInstance;

    constructor() {
        this.axios = axios.create({
            baseURL: environment.authServerUrl,
            withCredentials: true,  // Important for CORS
            headers: {
                'Content-Type': 'application/json',
            }
        })
    }
};