import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "@mantine/core/styles.css";
import { createTheme, Loader, MantineProvider } from "@mantine/core";
import "@mantine/notifications/styles.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import { Notifications } from "@mantine/notifications";
import { RingLoader } from "./components/appLoader/ringLoader";
import { initializeApp } from "firebase/app";
import { firebaseConfig } from "./firebase";

const theme = createTheme({
  primaryColor: "gray",

  components: {
    Loader: Loader.extend({
      defaultProps: {
        loaders: { ...Loader.defaultLoaders, ring: RingLoader },
        type: "ring",
      },
    }),
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const app = initializeApp(firebaseConfig);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MantineProvider theme={theme}>
        <Notifications />
        <App />
      </MantineProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
