import { Roles } from "../../types";

export const emailValidationRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export class AuthValidation {
    static validateEmail(email: string): string | null {
        if (!email) {
            return "Email is required";
        }
        if (!email.match(emailValidationRegex)) {
            return "Email is invalid";
        }
        return null;
    }

    static validatePassword(password: string): string | null {
        if (!password) {
            return "Password is required";
        }
        if (password.length < 6) {
            return "Password is too short";
        }
        return null;
    }

    static roleValidation(role: string): string | null {
        if (!role) {
            return "Role is required";
        }
        if (!Object.values(Roles).includes(role as Roles)) {
            return "Role is invalid";
        }
        return null
    }

    static validateAgree(agree: boolean): string | null {
        if (!agree) {
            return "You must agree to the terms and conditions";
        }
        return null;
    }
}