import {
  Flex,
  Space,
  Button,
  Text,
  Drawer,
  Image,
  Divider,
} from "@mantine/core";
import { ReactComponent as JrmIcon } from "../../assets/icon.svg";
import {
  IconBookmarkFilled,
  IconLock,
  IconLogin,
  IconLogout,
  IconMenuDeep,
} from "@tabler/icons-react";
import { AppRoutes, navbarLinks } from "../../models";
import styles from "./navbar.module.css";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { ScreenSize } from "../../utils";
import { User } from "../../types";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { AuthService } from "../../services/authService";

export function Navbar() {
  const isDesktop = useMediaQuery(ScreenSize.desktop);
  const isTab = useMediaQuery(ScreenSize.tab);
  const isMobile = useMediaQuery(ScreenSize.mobile);

  const [expanded, setExpanded] = useState<boolean>(false);
  const [hover, setHover] = useState<string>();
  const [highlighted, setHighlighted] = useState<string>("");
  const navigate = useNavigate();

  const user: User | null = useSelector((state: RootState) => state.auth.user);

  async function logout() {
    await AuthService.logout();
    setExpanded(false);
    navigate(AppRoutes.HOME);
  }

  return (
    <Flex w={"100%"} justify={"center"}>
      <Flex
        py={20}
        style={{ fontFamily: "Inter" }}
        justify={"space-between"}
        w={"70%"}
      >
        <Flex>
          <JrmIcon
            onClick={() => navigate(AppRoutes.HOME)}
            style={{ cursor: "pointer" }}
          />
          <Space w={30} />
        </Flex>
        {!user && (
          <Flex align={"center"} className={styles.navResponsive}>
            <IconBookmarkFilled />
            <Space w={30} />
            <Button
              color="black"
              w={100}
              h={45}
              radius={"sm"}
              variant="default"
              style={{ border: "2px solid black" }}
              onClick={() => navigate(`${AppRoutes.LOGIN}`)}
            >
              <Text size="sm">LOGIN</Text>
            </Button>
            <Space w={30} />
            <Button
              color="black"
              w={100}
              h={45}
              radius={"sm"}
              onClick={() => navigate(`${AppRoutes.SIGNUP}`)}
            >
              <Text size="sm">SIGN UP</Text>
            </Button>
          </Flex>
        )}
        {user && (
          <Flex align={"center"} className={styles.navResponsive}>
            <Text size="sm">{user.name}</Text>
            <Space w={30} />
            <Button color="black" w={100} h={45} radius={"sm"} onClick={logout}>
              <Text size="sm">Logout</Text>
            </Button>
          </Flex>
        )}
        <Flex
          className={styles.navResponsiveIcon}
          align={"center"}
          justify={"center"}
          onClick={() => setExpanded(!expanded)}
        >
          <IconMenuDeep />
        </Flex>
      </Flex>

      <Drawer
        opened={expanded}
        onClose={() => setExpanded(false)}
        position={"right"}
        overlayProps={{ backgroundOpacity: 0.2, blur: 4 }}
        transitionProps={{
          duration: 300,
        }}
        styles={{
          content: {
            height: "250",
            borderTopLeftRadius: 12,
            borderTopRightRadius: 12,
          },
          overlay: {
            height: "250",
          },
        }}
      >
        <Flex direction={"column"}>
          {!user && (
            <Flex
              p={6}
              my={2}
              onClick={() => {
                setExpanded(false);
                navigate(`${AppRoutes.LOGIN}`);
              }}
              style={{ cursor: "pointer" }}
              align={"center"}
            >
              <IconLogin />
              <Text size="lg" fw={500} ml={8}>
                Login
              </Text>
            </Flex>
          )}
          {!user && (
            <Flex
              p={6}
              my={2}
              onClick={() => {
                setExpanded(false);
                navigate(`${AppRoutes.LOGIN}`);
              }}
              style={{ cursor: "pointer" }}
              align={"center"}
            >
              <IconLock />
              <Text size="lg" fw={500} ml={8}>
                Signup
              </Text>
            </Flex>
          )}
          {user && (
            <Flex
              p={6}
              my={2}
              style={{ cursor: "pointer" }}
              onClick={logout}
              align={"center"}
            >
              <Image
                src={user.profilePicture}
                alt={user.name}
                w={35}
                style={{ borderRadius: "50%" }}
                mr={12}
              />
              <Text style={{ fontSize: 22 }} fw={500}>
                {user.name}
              </Text>
            </Flex>
          )}
          {user && (
            <Flex
              p={6}
              my={2}
              style={{ cursor: "pointer" }}
              onClick={logout}
              align={"center"}
            >
              <IconLogout />
              <Text size="lg" ml={8} fw={500}>
                Logout
              </Text>
            </Flex>
          )}
        </Flex>
      </Drawer>
    </Flex>
  );
}
