import { getAuth, GoogleAuthProvider, signInWithPopup, UserCredential } from 'firebase/auth';
import { AuthConnector } from '../serverConnectors';
import { HttpStatusCode } from 'axios';
import { User } from '../types';
import store from '../store/store';
import { setUser } from '../store/authSlice';

const authConnector: AuthConnector = new AuthConnector();

export const AuthService = {
    doGoogleSSO: async (): Promise<User | null> => {
        let user: User | null = null;

        try {
            const auth = getAuth();
            const provider = new GoogleAuthProvider();

            provider.addScope('https://www.googleapis.com/auth/admin.directory.userschema.readonly');

            const userCredential: UserCredential = await signInWithPopup(auth, provider);
            const idToken = await userCredential.user.getIdToken();
            const response = await authConnector.axios.post('/user/sso/google', {
                uid: userCredential.user.uid,
                name: userCredential.user.displayName,
                email: userCredential.user.email,
                profilePicture: userCredential.user.photoURL,
                idToken: idToken,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            });

            if (response.status === HttpStatusCode.Ok) {
                user = response.data;
            }

            if (!user) {
                throw new Error('User not found');
            }

            sessionStorage.setItem('user', JSON.stringify(user));
            sessionStorage.setItem('token', idToken);
            sessionStorage.setItem('firebaseUser', JSON.stringify(userCredential.user));
            store.dispatch(setUser(user));
        }
        catch (error: any) {
            console.error(error.message);
        }

        return user;
    },

    logout: async (): Promise<void> => {
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('firebaseUser');
        store.dispatch(setUser(null));
    }
}