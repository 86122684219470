import { useSelector } from "react-redux";
import { User } from "../../types";
import { RootState } from "../../store";
import { Flex, Image, Text } from "@mantine/core";

export function DashboardPage() {
  const user: User | null = useSelector((state: RootState) => state.auth.user);

  return (
    <>
      {user && (
        <Flex
          direction="column"
          justify={"center"}
          align={"center"}
          m={16}
          p={16}
        >
          <Text size="xl" fw={500} mb={4}>Welcome!</Text>
          <Image
            src={user.profilePicture}
            alt={user.name}
            style={{ borderRadius: "50%" }}
            w={100}
          />
          <Text fw={500} mt={4}>{user.name}</Text>
        </Flex>
      )}
    </>
  );
}
