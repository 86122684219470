import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../types";

export type AuthSlice = {
    user: User | null;
};

const authSliceState: AuthSlice = {
    user: null
};

export const authSlice = createSlice({
    name: "authSlice",

    initialState: authSliceState,
    reducers: {
        setUser(state: AuthSlice, action: PayloadAction<User | null>) {
            state.user = action.payload;
            return state;
        },
        resetUser(state: AuthSlice) {
            state.user = null;
            return state;
        }
    }
});

export const { setUser, resetUser } = authSlice.actions;
export default authSlice.reducer;